import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
    listaUsuarios: [],
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
    alteraListaUsuarios(state, data) {
      state.listaUsuarios = data
    },
  },
  actions: {

    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/times', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    fetchUsuarios({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { queryParams })
          .then(response => {
            commit('alteraListaUsuarios', response.data)
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },

    show(ctx, idTime) {
      return new Promise((resolve, reject) => {
        axios
          .get(`times/${idTime}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    store(ctx, itemData) {
      const formData = new FormData()

      formData.append('nome', itemData.nome)
      formData.append('definicao', itemData.definicao)

      Object.keys(itemData.integrantes)
        .forEach(chave => {
          formData.append(`users[${chave}]`, itemData.integrantes[chave].id)
        })

      if (itemData.imagem) {
        formData.append('imagem', itemData.imagem)
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/times', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        formData.append('nome', itemData.nome)
        formData.append('definicao', itemData.definicao)

        Object.keys(itemData.users)
          .forEach(chave => {
            formData.append(`users[${chave}]`, itemData.users[chave].id || itemData.users[chave].uuid)
          })

        if (itemData.imagemSelected) {
          formData.append('imagem', itemData.imagemSelected)
        }

        formData.append('_method', 'PUT')

        axios.post(`times/${itemData.uuid}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/times/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
