<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titulo }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Nome do Time -->

          <validation-provider
            #default="validationContext"
            name="Nome do Time"
            rules="required"
          >
            <b-form-group
              label="Nome do Time"
              label-for="nome-time"
            >
              <b-form-input
                id="nome-time"
                v-model="itemData.nome"
                name="nome_time"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Informe o nome do time"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Definição do Time -->

          <validation-provider
            #default="validationContext"
            name="Definição do Time"
            rules="required"
          >
            <b-form-group
              label="Definição do Time"
              label-for="definicao-time"
            >
              <b-form-input
                id="definicao-time"
                v-model="itemData.definicao"
                name="definicao_time"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Informe a definição do time"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Integrantes  -->

          <b-form-group
            label="Integrantes"
            label-for="integrantes"
          >
            <select-com-pesquisa
              placeholder="Escolha os Integrantes"
              url="/users"
              :multiple="true"
              :itens-para-edicao="[]"
              @selecionados="tratarEventoIntegrantesSelecionados"
            />

          </b-form-group>

          <!-- imagem  -->
          <div class="d-flex justify-content-start mt-2">
            <div class="d-block">
              <b-form-file
                ref="fileInput"
                label="Imagem"
                accept="image/jpeg, image/png"
                label-for="imagem"
                browse-text="Procurar"
                placeholder="Escolha uma imagem"
                drop-placeholder="Escolha uma imagem"
                @change="handleFileChange"
              />
            </div>

          </div>
          <div
            v-if="selectedFile"
            class="d-flex justify-content-start mt-2 align-items-center"
          >
            <b-img
              thumbnail
              :src="previewUrl"
              alt="Imagem"
              :style="{ width: '50px', height: '50px' }"
            />
            <small class="ml-2">Pré visualização da imagem</small>
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BImg, BSidebar,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'

export default {
  components: {
    SelectComPesquisa,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BImg,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    titulo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      selectedFile: null,
      previewUrl: null,

    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      nome: '',
      integrantes: [],
      imagem: null,

    }
    const isAddNewSidebarActive = ref(false)
    // Use toast
    const toast = useToast()

    const itemData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-times/store', itemData.value)
        .then(respo => {
          emit('refetch-data')
          emit('update:is-add-new-sidebar-active', false)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Time adicionado com sucesso',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            const listaErros = error.response.data.errors
            for (const field in listaErros) {
              if (listaErros.hasOwnProperty(field)) {
                const errorMessages = listaErros[field]
                errorMessages.forEach(errorMessage => {
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: errorMessage,
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  })
                })
              }
            }
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0]
      this.selectedFile = file
      this.itemData.imagem = file

      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          this.previewUrl = reader.result
        }
        reader.readAsDataURL(file)
      } else {
        this.previewUrl = null
      }
    },
    tratarEventoIntegrantesSelecionados(itens) {
      this.itemData.integrantes = itens
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
